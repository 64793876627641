import React from 'react'
import {NavLink,Link} from "react-router-dom";

const Footer = () => {

// Get a reference to the div you want to show

// Function to toggle the visibility of the div based on scroll position
function toggleDivVisibility() {
const hiddenDiv = document.getElementById('scrolltop');
if(hiddenDiv){
    if (window.scrollY > 200) { // Adjust the scroll threshold as needed
        hiddenDiv.style.display = 'flex';
    } else {
        hiddenDiv.style.display = 'none';
    }
}
}

// Add a scroll event listener to trigger the function
window.addEventListener('scroll', toggleDivVisibility);

// Initial check in case the user starts the page already scrolled
toggleDivVisibility();





  return (
    <>
 <footer>
        <div className="container">

            <div className="short-intro m-auto text-center mt-0 pb-3 d-block d-md-none">
                <div className="footer-logo mb-4">
                    <img src="/images/icon/footer-logo.svg" alt="Atmosphere" title="Atmosphere" />
                </div>
                <p>Atmosphere is simply dummy text of the typesetting industry. Lorem Ipsum has been the standard .</p>
            </div>

            <div className="newsletter d-none">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <span>Subscribe to Our Newsletter</span>
                    </div>
                    <div className="col-md-7">
                        <div className="newsletter-input ms-auto">
                            <div className="input-group">
                                <input type="text" className="form-control rounded-0" placeholder="Email Address" aria-label="Email Address" aria-describedby="button-addon2"/>
                                <button className="btn subscribe-btn border-0 rounded-0" type="button" id="button-addon2"><img src="/images/icon/subscribe.svg" alt="Subscribe" title="Subscribe"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /newsletter --> */}

            <div className="row mt-lg-5 mt-4 pt-0 pt-lg-4">
                <div className="col-xl-3 col-lg-5">
                    <div className="address">
                        <div className="footer-heading mb-lg-5 mb-4">
                            <span>Contact</span>
                        </div>
                        <address>
                        <p>
                            <span><i className="fa fa-map-marker"></i></span> 
                            <div className='address-view'>
                                <span className='d-flex w-100 address-country'>India</span>
                                Unit No.114, 1st Floor, Inspire BKC, G Block, BKC Main Road, Bandra Kurla Complex, Mumbai, Maharashtra 400051.</div>                                
                            </p>

                            <p>
                                <span><i className="fa fa-map-marker"></i></span> 
                                <div className='address-view'>
                                    <span className='d-flex w-100 address-country'>Dubai</span>
                                    Meydan Grandstand, 6th Floor, Meydan Road, Nad Al Sheba, Dubai, U.A.E.</div>
                                                  
                            </p>
                            
                            <p className='telephone-number'><a href="tel:+02235230121"><span><i className="fa fa-phone"></i></span> <span>022-35230121</span></a></p>
                            <p><a href="mailto:info@theatmosphereliving.com"><span><i className="fa fa-envelope"></i></span> <span>info@theatmosphereliving.com</span></a></p>
                        </address>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 d-none d-md-flex">
                    <div className="short-intro about-atomsphere-foot-logo me-lg-auto ms-lg-auto pt-lg-0 mt-0 text-lg-center">
                        <div className="footer-logo text-lg-center text-md-start mb-4">
                            <img src="/images/icon/footer-logo.svg" alt="Atmosphere" title="Atmosphere" />
                        </div>
                        <div className="footer-about">
                            <p>Experience intuitive luxury crafted with your desires at heart, where every detail is tailored to exceed expectations.</p>
                        </div>
                        <div className="social-media">
                            <ul className="list-unstyled mb-0 d-flex justify-content-lg-center">
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <div className="footer-links">
                        <div className="footer-heading mb-lg-5 mb-4">
                            <span>Links</span>
                        </div>
                        <ul className="m-0 p-0 list-unstyled">
                            <li className="nav-item">
                                <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">About us</a>
                            </li>
                            <li className="nav-item d-none">
                                <a className="nav-link" href="#">Concept</a>
                            </li>
                            <li className="nav-item d-none">
                                <a className="nav-link" href="#">News & Blogs</a>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/CustomerRelation">Contact</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright d-flex align-items-center justify-content-between">
                <div className="copyright-text">
                    <span>Copyright &copy; Atmosphere Living 2023. All Rights Reserved.</span>
                </div>
                <div className="privacy d-flex align-items-center">
                    <a className="nav-link active" aria-current="page" href="#">Privacy</a>
                    <a className="nav-link" href="#">Terms and Conditions</a>
                </div>
            </div>
        </div>
        <a href="#" style={{"display":"none"}} id="scrolltop"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
    </footer>
{/* <footer>
        <div className="container">

            <div className="short-intro m-auto text-center mt-0 pb-3 d-block d-md-none">
                <div className="footer-logo mb-4">
                    <img src="/images/icon/footer-logo.svg" alt="Atmosphere" title="Atmosphere" />
                </div>
                <p>Atmosphere is simply dummy text of the typesetting industry. Lorem Ipsum has been the standard .</p>
            </div>

            <div className="newsletter">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <span>Subscribe to Our Newsletter</span>
                    </div>
                    <div className="col-md-7">
                        <div className="newsletter-input ms-auto">
                            <div className="input-group">
                                <input type="text" className="form-control rounded-0" placeholder="Email Address" aria-label="Email Address" aria-describedby="button-addon2"/>
                                <button className="btn subscribe-btn border-0 rounded-0" type="button" id="button-addon2"><img src="/images/icon/subscribe.svg" alt="Subscribe" title="Subscribe"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-3 col-lg-5">
                    <div className="address">
                        <div className="footer-heading mb-lg-5 mb-4">
                            <span>Contact</span>
                        </div>
                        <address>
                            <p><a href="#"><span><i className="fa fa-map-marker"></i></span> <span>#123, First Floor, Landmark,Mumbai, Maharashtra</span></a></p>
                            <p><a href="tel:+918544674242"><span><i className="fa fa-phone"></i></span> <span>+91 85446 74242</span></a></p>
                            <p><a href="mailto:info@atmosphereliving.com"><span><i className="fa fa-paper-plane"></i></span> <span>info@atmosphereliving.com</span></a></p>
                        </address>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 d-none d-md-flex">
                    <div className="short-intro about-atomsphere-foot-logo me-lg-auto ms-lg-auto pt-lg-0 mt-0 text-lg-center">
                        <div className="footer-logo text-lg-center text-md-start mb-4">
                            <img src="/images/icon/footer-logo.svg" alt="Atmosphere" title="Atmosphere" />
                        </div>
                        <div className="footer-about">
                            <p>Atmosphere is simply dummy text of the typesetting industry. Lorem Ipsum has been the standard .</p>
                        </div>
                        <div className="social-media">
                            <ul className="list-unstyled mb-0 d-flex justify-content-lg-center">
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                    <div className="footer-links">
                        <div className="footer-heading mb-lg-5 mb-4">
                            <span>Links</span>
                        </div>
                        <ul className="m-0 p-0 list-unstyled">
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link active" aria-current="page" >Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">About us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Concept</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">News & Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright d-flex align-items-center justify-content-between">
                <div className="copyright-text">
                    <span>Copyright &copy; Atmosphere Living 2023. All Rights Reserved.</span>
                </div>
                <div className="privacy d-flex align-items-center">
                    <a className="nav-link active" aria-current="page" href="#">Privacy</a>
                    <a className="nav-link" href="#">Terms and Conditions</a>
                </div>
            </div>
        </div>
        <a href="#" id="scrolltop"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
    </footer> */}
    {/* <footer>
        <div className="container">

            <div className="short-intro m-auto text-center mt-5 d-block d-md-none">
                <div className="footer-logo mb-4">
                    <img src="/images/icon/footer-logo.svg" alt="Atmosphere" title="Atmosphere" />
                </div>
                <p>Atmosphere is simply dummy text of the typesetting industry. Lorem Ipsum has been the
                    standard .</p>
            </div>

            <div className="newsletter">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <span>Subscribe to Our Newsletter</span>
                    </div>
                    <div className="col-md-7">
                        <div className="newsletter-input ms-auto">
                            <div className="input-group">
                                <input type="text" className="form-control rounded-0" placeholder="Email Address"
                                    aria-label="Email Address" aria-describedby="button-addon2"/>
                                <button className="btn subscribe-btn border-0 rounded-0" type="button"
                                    id="button-addon2"><img src="/images/icon/subscribe.svg" alt="Subscribe" title="Subscribe"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-4 col-lg-5">
                    <div className="address">
                        <div className="footer-heading mb-lg-5 mb-4">
                            <span>Contact</span>
                        </div>
                        <address>
                            <p><a href="#"><span><i className="fa fa-map-marker"></i></span> <span>#123, First Floor, Landmark,Mumbai, Maharashtra</span></a></p>
                            <p><a href="tel:+918544674242"><span><i className="fa fa-phone"></i></span> <span>+91 85446 74242</span></a></p>
                            <p><a href="mailto:info@atmosphereliving.com"><span><i className="fa fa-paper-plane"></i></span> <span>info@atmosphereliving.com</span></a></p>
                        </address>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-4 d-none d-md-flex">
                    <div className="short-intro m-lg-auto text-lg-center">
                        <div className="footer-logo mb-4">
                            <img src="/images/icon/footer-logo.svg" alt="Atmosphere" title="Atmosphere" />
                        </div>
                        <p>Experience intuitive luxury crafted with your desires at heart, where every detail is tailored to exceed expectations.</p>
                        <div className="social-media">
                            <ul className="list-unstyled mb-0 d-flex justify-content-lg-center">
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a className="rounded-pill d-flex align-items-center justify-content-center" href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                            </ul>                           
                            
                        </div>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-3">
                    <div className="footer-links">
                        <div className="footer-heading mb-lg-5 mb-4">
                            <span>Links</span>
                        </div>
                        <ul className="m-0 p-0 list-unstyled">
                            <li className="nav-item">
                                <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">About us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Concept</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">News & Blogs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright d-flex align-items-center justify-content-between">
                <div className="copyright-text">
                    <span>Copyright &copy; Atmosphere Living 2023. All Rights Reserved.</span>
                </div>
                <div className="privacy d-flex align-items-center">
                    <a className="nav-link active" aria-current="page" href="#">Privacy</a>
                    <a className="nav-link" href="#">Terms and Conditions</a>
                </div>
            </div>
        </div>
        <a href="#" id="scrolltop"><i className="fa fa-angle-up" aria-hidden="true"></i></a>
    </footer> */}
    
    </>
  )
}

export default Footer